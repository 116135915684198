import { UserLayout } from "@/src/layouts/UserLayout";
import { Card } from "@/src/components";
import { Balance, Boosts, Metrics, RechargeBalance, Tvl } from "@/src/views/home";

export const Dashboard = () => {
  return (
    <UserLayout>
      <div className="grid grid-cols-12 gap-4 max-w-full w-full h-full m-auto p-5">
          <Metrics />
        <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
          <Tvl />
          <Balance />
          <Boosts />
          <RechargeBalance />
          </div>
        </div>

    </UserLayout>
  );
};
