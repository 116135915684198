import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";

export const ChadDeposit = () => {
  const { chain } = useAccount();
  const { onChadDeposit, onChadWithdraw, onChadEnable } = useStake();
  const { address, isConnected } = useAccount();
  const { balance, chadBalance, chadEnabled, harvestFee } = useBalances();
  const submitButtonRef = useRef(null);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const [{ isStake, placeholder }, setOption] = useState({
    isStake: true,
    placeholder: "Amount to deposit",
  });

  const [{ stakeInput, unstakeInput }, setInput] = useState({
    stakeInput: "",
    unstakeInput: "",
  });
  useEffect(() => {
    submitButtonRef.current &&
      (() => {
        submitButtonRef.current.disabled = true;
        if (!isCorrectChain) {
          submitButtonRef.current.innerHTML = "Wrong Network";
          return;
        }

        if (isStake) {
          if (balance == 0 || stakeInput > balance) {
            submitButtonRef.current.innerHTML = "insufficient balance";
            return;
          }
          if (stakeInput) {
            submitButtonRef.current.innerHTML = "Deposit";
            submitButtonRef.current.disabled = false;
            return;
          }
          submitButtonRef.current.innerHTML = "Enter quantity";

          return;
        }

        if (chadBalance == 0 || unstakeInput > chadBalance) {
          submitButtonRef.current.innerHTML = "insufficient balance";
          submitButtonRef.current.disabled = true;
          return;
        }
        if (unstakeInput) {
          submitButtonRef.current.innerHTML = "Withdraw";
          submitButtonRef.current.disabled = false;
          return;
        }
        submitButtonRef.current.innerHTML = "Enter quantity";
          return;
      })();
  }, [isStake, stakeInput, unstakeInput, balance, chadBalance]);

  const onInputChange = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = isStake ? value : stakeInput;
    const _unstakeInput = isStake ? unstakeInput : value;
    setInput({
      stakeInput: _stakeInput,
      unstakeInput: _unstakeInput,
    });
  };

  const onUnstake = () => {
    setOption({
      isStake: false,
      placeholder: "Amount to withdraw",
    });
  };
  const onStake = () => {
    setOption({
      isStake: true,
      placeholder: "Amount to deposit",
    });
  };

  const onMaxBalance = () => {
    if (isStake)
      return setInput((prev) => ({
        ...prev,
        stakeInput: balance,
      }));
    setInput((prev) => ({
      ...prev,
      unstakeInput: chadBalance,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isStake)
      return toast.promise(onChadDeposit(stakeInput), {
        loading: "Transaction is in progress",
        success: "Successfully deposited CRB",
        error: (data) => data,
      });

    if (!isStake && unstakeInput == chadBalance && chadEnabled == "true" ){

    
      toast.promise(onChadEnable(false), {
        loading: "Transaction is in progress",
        success: "Successfully disabled CHAD",
        error: (data) => data,
    });
  }
    toast.promise(onChadWithdraw(unstakeInput), {
      loading: "Transaction is in progress",
      success: "Succesfully withdrew CRB",
      error: (data) => data,
    });
  };
  return (
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">
      <div className="flex justify-center">
       {(chadEnabled == "true" ) && <img src={process.env.PUBLIC_URL + 'chad_enabled.png'} width="350" heigh="350" style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} />}
       {(chadEnabled == "false") && <img src={process.env.PUBLIC_URL + 'chad_disabled.png'} width="350" heigh="350" style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} />}
       </div>
      <div className="flex justify-center gap-2 w-full">
        <Button
          onClick={onStake}
          className={clsx("text-foreground", {
            "bg-primary": isStake,
            "bg-primary/50": !isStake,
          })}
        >
          Deposit
        </Button>
        <Button
          onClick={onUnstake}
          className={clsx("text-foreground", {
            "bg-primary": !isStake,
            "bg-primary/50": isStake,
          })}
        >
          Withdraw
        </Button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mt-8 p-1 pr-0">
          <div className="flex w-full">
            <input
              value={isStake ? stakeInput : unstakeInput}
              max={isStake ? balance : chadBalance}
              onChange={onInputChange}
              placeholder={placeholder}
              className="text-foreground font-medium w-[80%] rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />

            <span className="w-1/5">
              <Button
                type="button"
                onClick={onMaxBalance}
                className="rounded-tl-none rounded-bl-none"
              >
                max
              </Button>
            </span>
          </div>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
        <table>
            <tr>
              <td>
                <p><b>CRB Balance</b> </p> 
              </td>
              <td>
              <p>{parseFloat(balance).toFixed(4)}</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>CHAD CRB Balance</b> </p> 
            </td>
              <td>
              <p>{parseFloat(chadBalance).toFixed(4)}</p>
              </td>
            </tr>
          </table>
          </div>
          {/*<p><b>CRB balance:</b> {parseFloat(balance).toFixed(2)}</p>
        </div>
        <div className="flex justify-center text-foreground/50">
        <p><b>Staked balance:</b> {parseFloat(stakeBalance).toFixed(2)} </p>*/}
        {/*<div className="flex justify-center text-foreground/50"><p>{!isStake && <p><b>Unstake fee (+5 harvest points):</b> {parseFloat(unstakeInput*5/100).toFixed(2)}</p>}</p></div>
        <div className="flex justify-center text-foreground/50"><p>{!isStake && <p><b>Unstake amount:</b> {(parseFloat(unstakeInput)-parseFloat(unstakeInput*5/100)).toFixed(2)}</p>}</p></div>
        <div className="flex justify-center py-2 text-foreground/50"></div>*/}
        <div className="border-t-2 border-foreground/30" />

        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
          {isConnected && <Button ref={submitButtonRef} type="submit">Deposit</Button>}
          
        </div>
      </form>
    </div>
  );
};
