import { cn } from "@/src/lib/utils";

export const NavLink = ({ icon, children, className, ...props }) => {
  return (
    <a
      {...props}
      className={cn(
        "group flex text-lg font-medium items-center gap-4 hover:bg-primary/20 rounded-md",
        className
      )}
    >
      <span className="group-hover:bg-primary/50 text-foreground bg-primary/20 p-2 rounded-md">
        {icon}
      </span>
      <p className="group-hover:text-foreground text-foreground/80">
        {children}
      </p>
    </a>
  );
};
