import { createContext, useContext, useEffect, useState } from "react";
//import { usePublicClient } from "wagmi";
import { CANARY_DAO_ABI, DAO_CONTRACT_ADDRESS } from "../lib/constants";
import { formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";

const defaultValues = {
  rebaseInterval: 0,
  lastRebaseTime: 0,
  rebaseRate: 0,
  getRebaseInfo: () => {},
};

const RebaseContext = createContext(defaultValues);

export const RebaseProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();
  const [state, setState] = useState({
    rebaseInterval: 0,
    lastRebaseTime: 0,
    rebaseRate: 0,
  });

  const getRebaseInfo = async (address) => {
    try {
      const [rebaseInterval, lastRebaseTime, rebaseRate] = await Promise.all([
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "rebaseInterval",
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "stakedBalances",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "rebaseRate",
        }),
      ]);

      setState({
        rebaseInterval: formatUnits(rebaseInterval, 0),
        lastRebaseTime: formatUnits(lastRebaseTime, 0),
        rebaseRate: formatUnits(rebaseRate, 0),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    isConnected && getRebaseInfo(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <RebaseContext.Provider
      value={{
        ...state,
        getRebaseInfo,
      }}
    >
      {children}
    </RebaseContext.Provider>
  );
};

export const useRebase = () => useContext(RebaseContext);
