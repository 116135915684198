import { Card } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { useFarms } from "@/src/hooks/useFarms";
import { Timer } from "@/src/components/deadlineTimer";
import { useStake } from "@/src/hooks/useStake";
import { Button } from "@/src/components";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";


export const CnyxBalance = () => {
  const { trbBalance } = useBalances();
  const { frbMinted } = useFarms();
  const { onUseFaucet } = useStake();
  const { address, isConnected } = useAccount();

  const handleFaucet = async () => {
    if (!isConnected) return;

    toast.promise(onUseFaucet(), {
      loading: "Transaction is in progress",
      success: "Transaction successfully completed",
      error: (data) => data,
    });
  };
  return (
    <Card className="md:h-3/6 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Balances</h2>
          </span>

          <div>
            
          </div>
        </span>

        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(trbBalance).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">TRB</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(frbMinted).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            FRB Points* 
          </h2>
        </div> 
        <div>
        <h2 className="text-xl font-semibold text-foreground/50">
            * FRB airdrop depends on the total amount of TRB burned.
        </h2>
        </div>
        <div className="flex items-center h-full">
            <Button onClick={handleFaucet} className="">
                  <h2 className="md:text-2xl text-xl text-foreground">TRB Faucet</h2>
            </Button>
        </div>
      </div>
    </Card>
    
  );
};
