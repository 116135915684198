import { createContext, useEffect, useState } from "react";
import { formatEther, formatUnits } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import {
  CANARY_DAO_ABI,
  DAO_CONTRACT_ADDRESS,
  ERC20_ABI,
  ERC20_CONTRACT_ADDRESS,
  NFT_ABI,
  NFT_CONTRACT_ADDRESS,
  LP_ABI,
  LP_CONTRACT_ADDRESS,
  LP2_CONTRACT_ADDRESS,
  REWARD_ABI,
  REWARD_CONTRACT_ADDRESS,
  CNYX_CONTRACT_ADDRESS,
  VOTE_CONTRACT_ADDRESS,
  VOTE_ABI,
  BRONZE_CONTRACT_ADDRESS,
  BRONZE_ABI,
  BURN2MINT_ABI,
  BURN2MINT_CONTRACT_ADDRESS,
  CRBPRICE_ABI,
  CRBPRICE_ADDRESS,
  CHAD_ABI,
  CHAD_ADDRESS,
  FARM_ABI,
  FARM_ADDRESS,
  XENOSFARM_ABI,
  XENOS_FARM_ADDRESS,
  XENOS2_FARM_ADDRESS,
  CEN_FARM_ADDRESS,
  FLRB2M_ABI,
  FLRB2M_ADDRESS,
  SILVER_CONTRACT_ADDRESS,
  trb_ABI,
  TRB_ADDRESS,
  GOLD_CONTRACT_ADDRESS
} from "../lib/constants";

// initial state
const defaultValues = {
  balance: 0,
  stakeBalance: 0,
  nftBalance: 0,
  nftBoost: 0,
  lpBalance: 0,
  lpBoost: 0,
  lpMinimum: 0,
  rewardPoints:0,
  bronzeRequirement:0,
  rewardRequirement:0,
  goldRequirement:0,
  cnyxBalance: 0,
  rewardReborn:0,
  rewardMinimum:0,
  rewardRebase:0,
  voteYes:0,
  voteNo:0,
  votes:0,
  voteOpen: false,
  unCharged:0,
  charged:0,
  chargeAmount:0,
  bronzeAmount:0,
  chargeReborn:0,
  deadline:0,
  nextExpire:0,
  crbPrice:0,
  chadBalance:0,
  harvestFee:0,
  chadEnabled:false,
  sgbBalance: 0,
  silverAmount:0,
  goldAmount:0,
  silverSupply:0,
  goldSupply:0,
  trbBalance:0,
  getBalances: () => {},
};
// context
export const BalanceContext = createContext(defaultValues);

export const BalanceProvider = ({ children }) => {
  const publicClient = usePublicClient();
  const { address, isConnected } = useAccount();

  const [state, setBalances] = useState({
    balance: 0,
    stakeBalance: 0,
    nftBalance:0,
    nftBoost:0,
    lpBalance: 0,
    lpBoost:0,
    lpMinimum:0,
    rewardPoints:0,
    bronzeRequirement:0,
    rewardRequirement:0,
    goldRequirement:0,
    cnyxBalance: 0,
    rewardReborn: 0,
    rewardMinimum:0,
    rewardRebase:0,
    voteYes:0,
    voteNo:0,
    votes:0,
    voteOpen: false,
    unCharged:0,
    charged:0,
    chargeAmount:0,
    bronzeAmount:0,
    chargeReborn:0,
    deadline:0,
    nextExpire:0,
    crbPrice:0,
    chadBalance:0,
    harvestFee:0,
    chadEnabled:false,
    sgbBalance: 0,
    silverAmount:0,
    goldAmount:0,
    silverSupply:0,
    goldSupply:0,
    trbBalance:0,
  });

  const getBalances = async (address) => {
    //try {
      const [balance, stakeBalance, nftBalance, lpBalance, nftBoost, lpBoost, rewardPoints, bronzeRequirement, rewardRequirement, goldRequirement, cnyxBalance, rewardReborn, rewardMinimum, rewardRebase, voteYes, voteNo, votes, voteOpen, unCharged, charged, chargeAmount, bronzeAmount, chargeReborn, deadline, nextExpire, crbPrice, chadBalance, harvestFee, sgbBalance, silverAmount, goldAmount, silverSupply, goldSupply, trbBalance] = await Promise.all([
        publicClient.readContract({
          abi: ERC20_ABI,
          address: ERC20_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "stakedBalances",
          args: [address],
        }),
        publicClient.readContract({
          abi: NFT_ABI,
          address: NFT_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "userLpBalance",
          args: [address, LP_CONTRACT_ADDRESS],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "calculateNftBoost",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "calculateLpBoost",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "getRewardPoints",
          args: [address],
          
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rewardNfts",
          args: [BRONZE_CONTRACT_ADDRESS],
          
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rewardNfts",
          args: [SILVER_CONTRACT_ADDRESS],
          
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rewardNfts",
          args: [GOLD_CONTRACT_ADDRESS],
          
        }),
        publicClient.readContract({
          abi: ERC20_ABI,
          address: CNYX_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "rebornAmount",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "rewardPointsMinimum",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "rewardPointsRebase",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_CONTRACT_ADDRESS,
          functionName: "votedYes",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_CONTRACT_ADDRESS,
          functionName: "votedNo",
          args: [],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_CONTRACT_ADDRESS,
          functionName: "getVotes",
          args: [address],
        }),
        publicClient.readContract({
          abi: VOTE_ABI,
          address: VOTE_CONTRACT_ADDRESS,
          functionName: "vote_enabled",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getUnChargedNfts",
          args: [address],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getChargedNfts",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "chargeAmount",
          args: [],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: BRONZE_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: REWARD_ABI,
          address: REWARD_CONTRACT_ADDRESS,
          functionName: "chargeReborn",
          args: [],
        }),
        publicClient.readContract({
          abi: BURN2MINT_ABI,
          address: BURN2MINT_CONTRACT_ADDRESS,
          functionName: "deadline",
          args: [],
        }),
        publicClient.readContract({
          abi: CANARY_DAO_ABI,
          address: DAO_CONTRACT_ADDRESS,
          functionName: "getNextChargeExpiration",
          args: [address],
        }),
        publicClient.readContract({
          abi: CRBPRICE_ABI,
          address: CRBPRICE_ADDRESS,
          functionName: "getcrbPrice",
          args: [],
        }),
        publicClient.readContract({
          abi: CHAD_ABI,
          address: CHAD_ADDRESS,
          functionName: "depositBalances",
          args: [address],
        }),
        publicClient.readContract({
          abi: CHAD_ABI,
          address: CHAD_ADDRESS,
          functionName: "getHarvestFee",
          args: [address],
        }),
        publicClient.readContract({
          abi: CRBPRICE_ABI,
          address: CRBPRICE_ADDRESS,
          functionName: "getBalance",
          args: [],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: SILVER_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: GOLD_CONTRACT_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: SILVER_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
        publicClient.readContract({
          abi: BRONZE_ABI,
          address: GOLD_CONTRACT_ADDRESS,
          functionName: "totalSupply",
          args: [],
        }),
        publicClient.readContract({
          abi: trb_ABI,
          address: TRB_ADDRESS,
          functionName: "balanceOf",
          args: [address],
        }),
      ]);
      setBalances({
        balance: formatEther(balance || "0"),
        stakeBalance: formatEther(String(stakeBalance).split(",")[1] || "0"),
        nftBalance: nftBalance || "0",
        nftBoost: parseInt(nftBoost) || "0",
        lpBalance: formatEther(String(lpBalance).split(",")[0]) || "0",
        lpBoost: parseInt(lpBoost) || "0",
        lpMinimum: formatEther(String(lpBalance).split(",")[1]) || "0",
        rewardPoints: formatEther(rewardPoints) || "0",
        bronzeRequirement: formatEther(bronzeRequirement) || "0",
        rewardRequirement: formatEther(rewardRequirement) || "0",
        goldRequirement: formatEther(goldRequirement) || "0",
        cnyxBalance: formatEther(cnyxBalance || "0"),
        rewardReborn: formatEther(rewardReborn || "0"),
        rewardMinimum: formatEther(rewardMinimum || "0"),
        rewardRebase: formatEther(rewardRebase || "0"),
        voteYes: voteYes || "0",
        voteNo: voteNo || "0",
        votes: votes || "0",
        votes: votes || "0",
        voteOpen: voteOpen || false,
        unCharged: unCharged || "0",
        charged: charged || "0",
        chargeAmount: formatEther(chargeAmount || "0"),
        bronzeAmount: bronzeAmount || "0",
        chargeReborn: formatEther(chargeReborn || "0"),
        deadline: formatUnits(deadline, 0),
        nextExpire: formatUnits(nextExpire,0),
        crbPrice: formatUnits(parseInt(crbPrice)/100000000000, 0),
        chadBalance: formatEther(chadBalance || "0"),
        harvestFee: formatEther(harvestFee || "0"),
        chadEnabled: String(stakeBalance).split(",")[3] || false,
        sgbBalance: sgbBalance || "0",
        silverAmount: silverAmount || "0",
        silverSupply: silverSupply || "0",
        goldAmount: goldAmount || "0",
        goldSupply: goldSupply || "0",
        trbBalance: formatEther(trbBalance || "0"),
      });
    //} catch (err) {}
    
  };

  useEffect(() => {
    isConnected && getBalances(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, isConnected]);

  return (
    <BalanceContext.Provider value={{ ...state, getBalances }}>
      {children}
    </BalanceContext.Provider>
  );
};
