import { Button, Card } from "@/src/components";
import { useStake } from "@/src/hooks/useStake";
import { ReloadIcon } from "@/src/icons";
import { useRebase } from "@/src/context";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import { useState, useEffect, useRef } from "react";
import { useBalances } from "@/src/hooks/useBalances";
import { activeChain } from "@/src/configs/chainConfig";

export const ChadButton = () => {
  const { chain } = useAccount();
  const [rebaseInterval, setRebaseInterval] = useState(0);
  const [lastRebaseTime, setLastRebaseTime] = useState(0);
  const [rebaseRate, setRebaseRate] = useState(0);
  const {chadEnabled} = useBalances();
  const [timeCheck, setTime] = useState(0);
  const{timeCheck: timeCheckProp} = useState(new Date());
  const { rebaseInterval: rebaseIntervalProp, lastRebaseTime: lastRebaseTimeProp, rebaseRate: rebaseRateProp} = useRebase();
  useEffect(() => {
    setRebaseInterval(parseInt(rebaseIntervalProp));
    setLastRebaseTime(parseInt(lastRebaseTimeProp));
    setRebaseRate(parseFloat(rebaseRateProp));
    setTime(timeCheckProp);
  }, [rebaseIntervalProp, lastRebaseTimeProp, rebaseRateProp, timeCheckProp]);
  const { isConnected } = useAccount();
  const { onChadEnable } = useStake();
  const [buttonText, setButtonText] = useState(false);

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });
  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const validate = () => {
    if (chadEnabled == "false"){
      return "Enable";
    }
    if (chadEnabled== "true"){
      return "Disable";
    }
    return "Enable";
  };
  useEffect(() => {
    const buttonText = validate();
    setButtonText(buttonText);
  }, [chadEnabled]);


  const chadEnable = async () => {
    if (!isConnected) return;
    if(chadEnabled == "false"){
      toast.promise(onChadEnable(true), {
        loading: "Transaction is in progress",
        success: "Successfully enabled CHAD",
        error: (data) => data,
      });
    }else if(chadEnabled == "true"){

    toast.promise(onChadEnable(false), {
      loading: "Transaction is in progress",
      success: "Successfully disabled CHAD",
      error: (data) => data,
    });
    }
  };
  return (
    <Card className="h-1/5">
      <div className="flex items-center h-full">
          <Button onClick={chadEnable} className="">
          <ReloadIcon className="h-7 w-8 text-foreground" />
          <h2 className="md:text-2xl text-xl text-foreground">{buttonText}</h2>
        </Button>
      </div>
    </Card>
  );
};
