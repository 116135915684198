import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useFarms } from "@/src/hooks/useFarms";
import { useAccount } from "wagmi";
import { useStake } from "@/src/hooks/useStake";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

export const FarmBalance = () => {
  const { farmBalance, pendingRewards, farmStatus } = useFarms();
  const [buttonText, setButtonText] = useState(false);
  const { isConnected } = useAccount();
  const { onEnableFarm, onClaimPendingRewards, onStakeCanary } = useStake();

  const validate = () => {
    if (farmStatus == 0){
      return "Enable Farm";
    }
    if (farmStatus== 1){
      return "Disable Farm";
    }
    return "Enable Farm";
  };
  useEffect(() => {
    const buttonText = validate();
    setButtonText(buttonText);
  }, [farmStatus]);

  const enableFarm = async () => {
    if (!isConnected) return;
    if(farmStatus == 0){
      toast.promise(onEnableFarm(true), {
        loading: "Transaction is in progress",
        success: "Successfully enabled Canary Farm",
        error: (data) => data,
      });
    }else if(farmStatus == 1){

    toast.promise(onEnableFarm(false), {
      loading: "Transaction is in progress",
      success: "Successfully disabled Canary Farm",
      error: (data) => data,
    });
    }
  };
  const claimRewards = async () => {
    if (pendingRewards <= 0 ) return;

      toast.promise(onClaimPendingRewards(), {
        loading: "Transaction is in progress",
        success: "Successfully claimed CRB",
        error: (data) => data,
      });
    };
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
        <h2 className="text-2xl text-foreground font-semibold">
        Xenos CRB/WSGB
          </h2>
          <div>
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(farmBalance).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">LP Tokens</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(pendingRewards).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending CRB Rewards</h2>
        </div>
        <div className="flex items-center h-full">
          <Button onClick={claimRewards} className="" disabled={pendingRewards==0}>
          <h2 className="md:text-xl text-xl text-foreground">Claim Rewards </h2>
        </Button>
      </div>
      <div className="flex items-center h-full">
          <Button onClick={enableFarm} className="" >
          <h2 className="md:text-xl text-xl text-foreground">{buttonText}</h2>
        </Button>
      </div>
      </div>
    </Card>
    
  );
};
