import { useEffect, useState } from "react";
import { useAccount, useWalletClient } from "wagmi";
import { useConfig } from 'wagmi'
import { Button } from "@/src/components";
import { shortenAddress } from "@/src/lib/address";
import { activeChain } from "@/src/configs/chainConfig";
import { switchNetwork } from "@/src/lib/utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";

export const ConnectButton = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { chain } = useAccount();
  const { data: walletProvider } = useWalletClient();

  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const onChainChanged = async () => {
    try {
      await switchNetwork(walletProvider, activeChain);
    } catch (err) {}
  };

  return (
    <>
      {isCorrectChain && (
        <Button
          type="button"
          onClick={() => open()}
          className="max-w-[250px] uppercase"
        >
          {shortenAddress(address) || "Connect"}
        </Button>

      )}

      {!isCorrectChain && (
        <Button
          type="button"
          onClick={onChainChanged}
          className="max-w-[250px] uppercase bg-red-600 text-background"
        >
          Wrong Network
        </Button>
      )}
    </>
  );
};
