import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";
import { ChadDeposit } from ".";

export const Chad = () => {
  const { chadBalance, harvestFee, chadEnabled } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">

          <div>
            
          </div>
        </span>

        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(chadBalance).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">CHAD CRB Balance</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
            {parseFloat(harvestFee).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            Next Harvest Fee
          </h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
            {chadEnabled=="false" && <p>Manual Harvest</p>}
            {(chadEnabled=="true" && parseFloat(chadBalance) >= parseFloat(harvestFee))  && <p>Auto Harvest</p>}
            {(chadEnabled=="true" && parseFloat(chadBalance) < (parseFloat(harvestFee)))  && <p>Insufficient Deposit</p>}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">
            Status
          </h2>
        </div>
      </div>
    </Card>
    
  );
};
