import Countdown from "react-countdown";

export const Timer = ({ time }) => {
  if (time)
    return (
      <Countdown
        date={time * 1000}
        renderer={({ days, hours, minutes, seconds, completed }) => {
          return (
            <span>
              {String(days)}d {String(hours).padStart(2,'0')}:{String(minutes).padStart(2,'0')}:{String(seconds).padStart(2,'0')}
            </span>
          );
        }}
      />
    );

  return null;
};
