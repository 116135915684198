import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useFarms } from "@/src/hooks/useFarms";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";


export const FlareBurn2Mint = () => {
  const { chain } = useAccount();
  const { onStakeCanary, onUnstakeCanary, onBurn2Mint } = useStake();
  const { address, isConnected } = useAccount();
  const { trbBalance } = useBalances();
  const { rebornBoost,bronzeBoost,silverBoost, harvestBoost,totalBoost, userCap, burnedAmount } = useFarms();
  const submitButtonRef = useRef(null);

  const [{ isStake, placeholder }, setOption] = useState({
    isStake: true,
    placeholder: "Amount to burn",
  });
  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);

  const [{ stakeInput, unstakeInput }, setInput] = useState({
    stakeInput: "",
    unstakeInput: "",
  });
  useEffect(() => {
    submitButtonRef.current &&
      (() => {
        submitButtonRef.current.disabled = true;
        if ( !isCorrectChain ) {
          submitButtonRef.current.innerHTML = "Wrong Network";
          return;
        }

        if (isStake) {
          if ((trbBalance == 0 || stakeInput > trbBalance) ) {
            submitButtonRef.current.innerHTML = "Insufficient balance";
            return;
          }
          if (stakeInput > (parseFloat(userCap).toFixed(2)-parseFloat(burnedAmount).toFixed(2))) {
            submitButtonRef.current.innerHTML = "Cannot burn more than cap";
            return;
          }
          if ( !isCorrectChain ) {
            submitButtonRef.current.innerHTML = "Wrong Network";
            return;
          }
          if (stakeInput) {
            submitButtonRef.current.innerHTML = "Mint FRB Points";
            submitButtonRef.current.disabled = false;
            return;
          }
          submitButtonRef.current.innerHTML = "Enter quantity";

          return;
        }

        submitButtonRef.current.innerHTML = "Enter quantity";
          return;
      })();
  }, [isStake, stakeInput, unstakeInput, trbBalance, rebornBoost,totalBoost,bronzeBoost,harvestBoost, userCap]);

  const onInputChange = (event) => {
    const value = event.target.valueAsNumber;
    const _stakeInput = isStake ? value : stakeInput;
    const _unstakeInput = isStake ? unstakeInput : value;
    setInput({
      stakeInput: _stakeInput,
      unstakeInput: _unstakeInput,
    });
  };

  const onUnstake = () => {
    setOption({
      isStake: false,
      placeholder: "Amount to unstake",
    });
  };
  const onStake = () => {
    setOption({
      isStake: true,
      placeholder: "Amount to burn",
    });
  };

  const onMaxBalance = () => {
    if (isStake)
      return setInput((prev) => ({
        ...prev,
        stakeInput: trbBalance,
      }));
    setInput((prev) => ({
      ...prev,
      unstakeInput: trbBalance,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isStake)
      return toast.promise(onBurn2Mint(stakeInput), {
        loading: "Transaction is in progress",
        success: "Successfully minted FRB Points",
        error: (data) => data,
      });
  };
  return (
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">

      <form onSubmit={handleSubmit}>
        <div className="mt-8 p-1 pr-0">
          <div className="flex w-full">
            <input
              value={stakeInput}
              max={trbBalance}
              onChange={onInputChange}
              placeholder={placeholder}
              className="text-foreground font-medium w-[80%] rounded-md rounded-tr-none rounded-br-none border-[3px] border-primary/60 border-r-0 bg-background hover:border-primary outline-0 px-3"
              type="number"
              required
            />

            <span className="w-1/5">
              <Button
                type="button"
                onClick={onMaxBalance}
                className="rounded-tl-none rounded-bl-none"
              >
                max
              </Button>
            </span>
          </div>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
        <table>
            <tr>
              <td>
                <p><b>TRB Balance:</b> </p> 
              </td>
              <td>
              <p>{parseFloat(trbBalance).toFixed(2)}</p>
              </td>
            </tr>
            <div className="py-2" />
            <tr>
              <td>
                <p><b>Amount that is burnable:</b> </p> 
              </td>
              <td>
              <p>{parseFloat(userCap).toFixed(2) > 0 && parseFloat(userCap).toFixed(2)-parseFloat(burnedAmount).toFixed(2)}</p>
              <p>{parseFloat(userCap).toFixed(2)-parseFloat(burnedAmount).toFixed(2) <= 0 && "0"}</p>
              </td>
            </tr>
            <div className="py-2" />
            <tr>
            <td>
                <p><b>Harvest Points Boost:</b> </p> 
            </td>
              <td>
              <p>{parseFloat(harvestBoost/10000).toFixed(2)}%</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>Charged Reborn NFT Boost:</b> </p> 
            </td>
              <td>
              <p>{parseFloat(rebornBoost/10000).toFixed(2)}%</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>Bronze NFT Boost:</b> </p> 
            </td>
              <td>
              <p>{parseFloat(bronzeBoost/10000).toFixed(2)}%</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>Silver NFT Boost:</b> </p> 
            </td>
              <td>
              <p>{parseFloat(silverBoost/10000).toFixed(2)}%</p>
              </td>
            </tr>
            <div className="border-t-2 border-foreground/30 py-2" />
            <tr>
            <td>
                <p><b>Total Boost (capped at ~100%):</b> </p> 
            </td>
              <td>
              <p>{parseFloat(totalBoost/10000).toFixed(2)}%</p>
              </td>
            </tr>
            <tr>
            <td>
                <p><b>FRB Points to be earned:</b> </p> 
            </td>
              <td>
              <p>{parseFloat(stakeInput*(1+(parseFloat(totalBoost)/1000000))).toFixed(2)}</p>
              </td>
            </tr>
          </table>
          </div>
        <div className="border-t-2 border-foreground/30" />
        <div className="mt-10">
          {!isConnected && (
            <div className="flex justify-center">
              <ConnectButton />
            </div>
          )}
          {isConnected && <Button ref={submitButtonRef} type="submit">Mint FRB Points</Button>}
          
        </div>
      </form>
    </div>
  );
};
