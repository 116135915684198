import {
  DiscordIcon,
  FileAdd,
  GithubIcon,
  LinkedIn,
  PodcastIcon,
  RedditIcon,
  Telegram,
  TwitterIcon,
  YoutubeIcon,
} from "@/src/icons";
export const socialLinks = [
  {
    icon: <DiscordIcon />,
    href: "https://discord.gg/hwgHbMMvev",
  },
  {
    icon: <TwitterIcon />,
    href: "https://twitter.com/TheCanaryReborn",
  },
];
