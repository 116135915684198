import { Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useRebase } from "@/src/context";
import { Timer } from "@/src/components/Timer";
import { useAccount } from "wagmi";
import { useState, useEffect } from "react";
import { useBalances } from "@/src/hooks/useBalances";


export const RebaseInfo = () => {
 // const { rebaseInterval, lastRebaseTime, rebaseRate } = useRebase();
  const { isConnected } = useAccount();
  const { stakeBalance, nftBoost, lpBoost, crbPrice } = useBalances();
  const [rebaseInterval, setRebaseInterval] = useState(0);
  const [lastRebaseTime, setLastRebaseTime] = useState(0);
  const [rebaseRate, setRebaseRate] = useState(0);
  const { rebaseInterval: rebaseIntervalProp, lastRebaseTime: lastRebaseTimeProp, rebaseRate: rebaseRateProp} = useRebase();
  const [time, setTime] = useState(new Date());
  const [timerValue, setTimerValue] = useState(0);

  const[boostedRebaserate, setBoostedRebaserate] = useState(0);
  

  useEffect(() => {
    setRebaseInterval(parseInt(rebaseIntervalProp));
    setLastRebaseTime(parseInt(lastRebaseTimeProp));
    setRebaseRate(parseFloat(rebaseRateProp));
    setTimerValue(parseInt(rebaseInterval) + parseInt(lastRebaseTime));
    setBoostedRebaserate(rebaseRate * (1+ ((nftBoost/1000000) + (lpBoost/1000000))));
  }, [rebaseIntervalProp, lastRebaseTimeProp, rebaseRateProp, nftBoost, timerValue, isConnected]);


  
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex gap-2">
            <h2 className="text-2xl font-semibold text-foreground">
              Harvest Info
            </h2>
          </span>

        </span>
        <p className="text-2xl md:text-3xl font-semibold text-foreground">
        {(parseInt(rebaseInterval)+parseInt(lastRebaseTime)) > 0 ? <Timer time={parseInt(rebaseInterval)+parseInt(lastRebaseTime)}/> : "00:00:00"}
        </p>
        <h2 className="text-xl font-semibold text-foreground/50">
          Time until next Harvest
        </h2>
        <p className="text-2xl md:text-3xl font-semibold text-foreground">
        {isConnected && parseFloat((boostedRebaserate/10000/100)*stakeBalance).toFixed(2)} {isConnected && " | " + parseFloat((((1+(boostedRebaserate/1000000))**365)-1)*100).toFixed(0) + "% APY"}
        {!isConnected && <p>N/A</p>}
        </p>
        <h2 className="text-xl font-semibold text-foreground/50">
          Next harvest amount
        </h2>
        {isConnected && <>
        <p className="text-2xl md:text-3xl font-semibold text-foreground">
        {isConnected && parseFloat(parseFloat((boostedRebaserate/10000/100)*stakeBalance).toFixed(4)*crbPrice).toFixed(4)+" $"}
        {!isConnected && <p>N/A</p>}

        </p>
        <h2 className="text-xl font-semibold text-foreground/50">
          Next harvest value
        </h2>
        </>}
      </div>
    </Card>
  );
};
