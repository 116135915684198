import { UserLayout } from "@/src/layouts/UserLayout";
import { Card } from "@/src/components";
import { Balance, RebaseButton, RebaseInfo, Recharge, RechargeBalance, RedeemRewards, RewardBalance, FarmBalance, Stake, XenosFarm, XenosFarm2, CENFarm } from "@/src/views/home";
import { FileAdd } from "@/src/icons";
import { RebaseProvider } from "@/src/context";

export const Farms = () => {
  return (
    <UserLayout>
      <div className="grid grid-cols-12 gap-4 max-w-[98rem] w-full h-full m-auto p-5">
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
          <FarmBalance />
          <XenosFarm2 />
          </div>
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
          <XenosFarm />
          <CENFarm />
          
          </div>
      </div>
    </UserLayout>
  );
};
