import { BankIcon, Query } from "@/src/icons";
import { Card, Button } from "@/src/components";
import { useFarms } from "@/src/hooks/useFarms";
import { useAccount } from "wagmi";
import { useStake } from "@/src/hooks/useStake";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

export const CENFarm = () => {
  const { stakeBalance, cenFarmBalance, cenPendingRewards, cenFarmStatus } = useFarms();
  const [buttonText, setButtonText] = useState(false);
  const { isConnected } = useAccount();
  const { onEnableCenFarm, onClaimCenRewards, onStakeCanary } = useStake();

  const validate = () => {
    if (parseInt(stakeBalance) < 10000){
      return "Stake 10K CRB to enable"
    }
    if (cenFarmStatus == 0){
      return "Enable Farm";
    }
    if (cenFarmStatus== 1){
      return "Disable Farm";
    }

    return "Enable Farm";
  };
  useEffect(() => {
    const buttonText = validate();
    setButtonText(buttonText);
  }, [cenFarmStatus]);

  const enableFarm = async () => {
    if (!isConnected) return;
    if(cenFarmStatus == 0){
      toast.promise(onEnableCenFarm(true), {
        loading: "Transaction is in progress",
        success: "Successfully enabled CEN Farm",
        error: (data) => data,
      });
    }else if(cenFarmStatus == 1){

    toast.promise(onEnableCenFarm(false), {
      loading: "Transaction is in progress",
      success: "Successfully disabled CEN Farm",
      error: (data) => data,
    });
    }
  };
  const claimRewards = async () => {
    if (cenPendingRewards <= 0 ) return;

      toast.promise(onClaimCenRewards(), {
        loading: "Transaction is in progress",
        success: "Successfully claimed CEN",
        error: (data) => data,
      });
    };
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
        <h2 className="text-2xl text-foreground font-semibold">
        Xenos CEN/WSGB
          </h2>
          <div>
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(cenFarmBalance).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">LP Tokens</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(cenPendingRewards).toFixed(4)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Pending CEN Rewards</h2>
        </div>
        <div className="flex items-center h-full">
          <Button onClick={claimRewards} className="" disabled={cenPendingRewards==0}>
          <h2 className="md:text-xl text-xl text-foreground">Claim Rewards </h2>
        </Button>
      </div>
      <div className="flex items-center h-full">
          <Button onClick={enableFarm} className="" disabled={parseInt(stakeBalance) < 10000}>
          <h2 className="md:text-xl text-xl text-foreground">{buttonText}</h2>
        </Button>
      </div>
      </div>
    </Card>
    
  );
};
