import { Card } from "@/src/components";
import { useMetrics } from "@/src/hooks/useMetrics";
import { useBalances } from "@/src/hooks/useBalances";

export const Metrics = () => {
  const { supply, liquidity, liquidity2,  staked, burned, minted } = useMetrics();
  const { balance, stakeBalance } = useBalances();
  return (
    <div className="col-span-12 md:col-span-8">
    <Card className="md:min-h-[calc(100vh-130px)] h-full">
      <div className="grid gap-3 mb-10 md:mb-24">
        <div className="max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
          Overall CRB Metrics
        </h2>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
        Supply 
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat(supply/1000000).toFixed(2)}M
        </h3>
        </div>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
           Inflation
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat((supply-minted-burned)/parseInt(supply)*100).toFixed(2)}% ({parseFloat((supply-minted-burned-(staked*0.025))/parseInt(supply)*100).toFixed(2)}% if unstaked)
        </h3>
        </div>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
        Burned
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat(burned/1000000).toFixed(2)}M
        </h3>
        </div>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
        Harvested
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat((supply-minted)/1000000).toFixed(2)}M
        </h3>
        </div>
        </div>
        <div className="justify-center">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
          CRB Distribution
        </h2>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
           Staked
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat((parseInt(staked)/parseInt(supply))*100).toFixed(0)}%
        </h3>
        </div>
        </div>
        <div className="md:border-2 py-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8 text-foreground/50">
        <h2 className="text-foreground text-2xl font-medium flex gap-2 justify-center">
        Liquidity Pool
        </h2>
        <div className="py-2">
        <h3 className="text-foreground text-2xl font-semibold flex gap-2 justify-center">
        {parseFloat(((parseInt(liquidity)+parseInt(liquidity2))/parseInt(supply))*100).toFixed(0)}%
        </h3>
        </div>
        </div>
       
      </div>
    </Card>
  </div>
    
  );
};
