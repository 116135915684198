import { useNav } from "@/src/hooks";
import { HamburgerIcon } from "@/src/icons";
import { Button, Card } from "@/src/components";
import { ConnectButton } from "./connectButton";
import { useAccount } from "wagmi";

export const Header = () => {
  const { onOpen } = useNav();
  const { isConnected } = useAccount();

  return (
    <header className="flex justify-between py-5 px-5 max-w-[98rem] m-auto w-full">
      <div>
        <Button
          id="modal-button"
          className="lg:hidden p-2 bg-primary h-full flex items-center"
          onClick={onOpen}
        >
          <HamburgerIcon className="w-8 h-6 text-foreground" />
        </Button>
      </div>

      <ConnectButton />
    </header>
  );
};
