import { BankIcon, Query } from "@/src/icons";
import { Card, Tooltip } from "@/src/components";
import { useBalances } from "@/src/hooks/useBalances";

export const RewardBalance = () => {
  const { rewardPoints, rewardMinimum, rewardRebase } = useBalances();
  return (
    <Card className="md:h-2/7 py-5">
      <div className="grid gap-3">
        <span className="flex items-center justify-between">
          <span className="flex items-center gap-2">
            
            <h2 className="text-2xl text-foreground font-semibold">Reward Overview</h2>
          </span>
          <div>
            
          </div>
        </span>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(rewardPoints).toFixed(2)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Harvest Points</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(rewardMinimum).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Minimum Staked CRB</h2>
        </div>
        <div>
          <p className="text-2xl md:text-3xl font-semibold text-foreground">
          {parseFloat(rewardRebase).toFixed(0)}
          </p>
          <h2 className="text-xl font-semibold text-foreground/50">Points earned each harvest</h2>
        </div>
      </div>
    </Card>
    
    
  );
};
