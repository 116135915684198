import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAccount } from "wagmi";
import { Button } from "@/src/components";
import { ConnectButton } from "@/src/components/connectButton";
import { useBalances } from "@/src/hooks/useBalances";
import { useFarms } from "@/src/hooks/useFarms";
import { useStake } from "@/src/hooks/useStake";
import { toast } from "react-hot-toast";
import { shortenAddress } from "@/src/lib/address";
import { LiquidityButton } from "@/src/views/home";
import { activeChain } from "@/src/configs/chainConfig";

export const RedeemRewards = () => {
  const { chain } = useAccount();
  const {  onMintReward } = useStake();
  const { address, isConnected } = useAccount();
  const { balance, stakeBalance, rewardPoints, rewardRequirement, rewardReborn, bronzeAmount, silverSupply } = useBalances();
  const { bronzeSupply } = useFarms();
  const [isValid, setValid] = useState(false);


  const validate = () => {
    if(rewardPoints < rewardRequirement){
      return true;
    }
    if(balance < rewardReborn){
      return true;
    }
      return false;
  };
  useEffect(() => {
    const isValid = validate();
    setValid(isValid);
  }, [balance, rewardPoints, rewardReborn, rewardReborn]);


  const handleSubmit = async (event) => {
    if (!isConnected) return;
    event.preventDefault();
      toast.promise(onMintReward(), {
        loading: "Transaction is in progress",
        success: "Reward NFT minted",
        error: (data) => data,
      });
  };
  const [{ isCorrectChain }, setState] = useState({
    isCorrectChain: true,
  });

  useEffect(() => {
    if (isConnected)
      setState({
        isCorrectChain: chain?.id === Number(activeChain.chainId),
      });
    
  }, [chain, isConnected]);
  return (    
    <div className="md:border-2 border-foreground/10 max-w-xl mx-auto w-full rounded-md md:p-8">
      <div className= "py-2 flex justify-center">
       <h2 className="text-foreground text-2xl font-medium flex gap-2 py-1">
                 Canary Silver NFT
      </h2>
      </div>
       <div className="flex justify-center">
       <img src={process.env.PUBLIC_URL + 'Silver_Coin.png'} width="350" heigh="350" style={{ alignItems: 'center', alignSelf: 'center', justifyContent: 'center' }} />
       </div>
      <form onSubmit={handleSubmit}>
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Silver Supply:</b> {parseFloat(silverSupply).toFixed(0)}/200</p>
        </div>
        <div className="py-2"/>
      <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Bronze NFT:</b> {parseFloat(bronzeAmount).toFixed(0)}/1</p>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
           <p><b>Harvest Points:</b> {parseFloat(rewardPoints).toFixed(2)}/{parseFloat(rewardRequirement).toFixed(2)}</p>
        </div>
        <div className="flex justify-center py-2 text-foreground/50">
           <p><b>CRB Required:</b> {parseFloat(balance).toFixed(2)}/{parseFloat(rewardReborn).toFixed(2)}</p>
        </div>
        <div className="border-t-2 border-foreground/30" />
        <div className="mt-10">
          <Button type="submit" disabled={!isCorrectChain || parseInt(rewardPoints) < parseInt(rewardRequirement) || rewardRequirement==0 || parseInt(balance) < parseInt(rewardReborn) || parseInt(bronzeAmount) < 1} ><p>Mint Canary Reborn Silver</p></Button>
        </div>
        
      </form>
    </div>
  );
};
