import { UserLayout } from "@/src/layouts/UserLayout";
import { Card } from "@/src/components";
import { Balance, RebaseButton, RebaseInfo, Recharge, RechargeBalance, RedeemRewards, RewardBalance, Stake } from "@/src/views/home";
import { FileAdd } from "@/src/icons";
import { RebaseProvider } from "@/src/context";
import { Chad, ChadButton, ChadDeposit } from "@/src/views/home";

export const ChadPage = () => {
  return (
    <UserLayout>
      <div className="grid grid-cols-12 gap-4 max-w-[98rem] w-full h-full m-auto p-5">
        <div className="col-span-12 md:col-span-8">
          <Card className="md:min-h-[calc(100vh-130px)] h-full">
            <div className="grid gap-3 mb-10 md:mb-24">
              <h2 className="text-foreground text-2xl font-medium flex gap-2">
                 Canary Harvest - Auto:Daily (C.H.A.D.)
              </h2>
              <p className="text-foreground"></p>
            </div>
           <ChadDeposit />
          </Card>
        </div>
          <div className="col-span-12 md:col-span-4 h-full flex flex-col gap-4">
         <Chad />
         <ChadButton />
          </div>
      </div>
    </UserLayout>
  );
};
