import { cn } from "@/src/lib/utils";

export const Link = ({ children, className, ...props }) => {
  return (
    <a
      {...props}
      className={cn(
        "rounded-md p-2 bg-primary/20 flex items-center justify-center hover:bg-primary/50 cursor-pointer",
        className
      )}
    >
      {children}
    </a>
  );
};
