export const TwitterIcon = () => {
  return (
    <svg
      fill="currentColor"
      width="20"
      height="20"
      viewBox="0 0 1200.000000 1084.000000"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Twitter</title>
      <g transform="translate(0.000000,1084.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
      <path d="M2 10808 c3 -25 518 -707 2231 -2950 1225 -1605 2230 -2924 2233
-2931 3 -10 -2764 -3204 -4254 -4909 -14 -17 30 -18 904 -18 l920 0 1635 1867
c898 1027 1645 1881 1659 1896 l26 29 1449 -1896 1450 -1896 1853 0 1853 0
-154 203 c-85 111 -1147 1515 -2360 3120 l-2207 2917 2018 2298 2018 2297
-915 3 c-679 1 -917 -1 -928 -9 -7 -7 -698 -785 -1535 -1730 -993 -1122 -1525
-1715 -1532 -1710 -6 5 -596 783 -1311 1730 l-1300 1720 -1879 0 -1878 1 4
-32z m6504 -5355 c1810 -2392 3294 -4354 3298 -4361 6 -10 -95 -12 -502 -10
l-510 3 -3257 4255 c-1792 2340 -3293 4301 -3337 4358 l-79 102 549 0 548 0
3290 -4347z"></path>
      </g>
    </svg>
  );
};
