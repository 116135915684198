import { cn } from "@/src/lib/utils";

export const Card = ({ className = "", children }) => {
  return (
    <div
      className={cn(
        "bg-card text-secondary shadow-xl p-6 rounded-lg",
        className
      )}
    >
      {children}
    </div>
  );
};
