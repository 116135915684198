import { Header } from "@/src/components";
import { Sidebar } from "@/src/components";
import { NavProvider } from "@/src/context";

export const UserLayout = ({ children }) => {
  return (
    <NavProvider>
      <div className="min-h-screen flex bg-gradient-to-t from-primary to-secondary">
        <Sidebar />

        <div className="flex-grow flex flex-col">
          <Header />
          <main className="flex-grow">{children}</main>
        </div>
      </div>
    </NavProvider>
  );
};
